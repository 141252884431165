const Q = require(`q`);
const _ = require(`underscore`);
const __original_sample_data_list = require(`@/lib/data-service/default/get_sample_data_list/__original_sample_data_list`);

const get_sample_data_entity = function (pParameter) {
    if (!pParameter) {
        pParameter = {};
    }

    const id = pParameter.id || ``;

    console.log(`调用get_sample_data_entity，id=${id}`);

    const entity = _.chain(__original_sample_data_list)
        .find(function (m) {
            return m.id === id;
        })
        .value()
    ;

    return Q.resolve({
        entity: entity,
    });
};

module.exports = get_sample_data_entity;
