import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import moment from 'moment';

import get_sample_data_entity from '@/lib/data-service/default/get_sample_data_entity';
import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api';
import breadcrumb_api from '@/component/admin-layout/1.0.0/component/breadcrumb/api';

export default {
    components: {},
    data() {
        return {
            entity: {
                id: ``,
            },
            entity_extend: {
                id: ``,
            },
            input: ``,
        }
    },
    methods: {
        __init_nav() {
            const __this = this;
            const id = __this.$route.query.id;

            const tag_name = `示例数据详情(id=${id})`;
            history_tag_api.modify_tag_name_by_route({
                route: __this.$route,
                tag_name: tag_name,
            });
            breadcrumb_api.set_entity_list_by_get_entity_list({
                get_entity_list(p) {
                    const original_entity_list = p.original_entity_list;
                    if (original_entity_list.length <= 0) return original_entity_list;
                    const last = _.last(original_entity_list);
                    last.name = tag_name;
                    return original_entity_list;
                },
            });

        },

        __init_data() {
            const __this = this;
            const id = __this.$route.query.id;

            get_sample_data_entity({id}).then(function (data) {
                __this.entity = data.entity;
                __this.entity_extend = extend(true, {}, data.entity);
            });
        },

        __btn_go_back_click() {
            const __this = this;

            history_tag_api.remove_tag_by_route({
                route: __this.$route,
            });

            // __this.$router.back(-1);//用这个容易有问题
            __this.$router.replace({
                name: `admin-sample-data-list`,
            });
        },
    },
    created() {
        // const __this = this;
    },
    mounted() {
        const __this = this;
        __this.__init_data();//编辑页面，建议要在mounted事件调用接口进行初始化
    },
    activated() {
        const __this = this;
        __this.__init_nav();

        // __this.__init_data();//编辑页面，建议不要在activated事件调用接口进行初始化
    },
    deactivated() {
        // const __this = this;
    },
}
